import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultDialog, {
  DefaultDialogImplementationProps,
} from "../DefaultDialog";
import FaceIconBrand from "../../theme/icons/FaceIconBrand";
import { convertPxToRem } from "../../utils";
import { slideUpModalStyle } from "../../theme";
import IconLoader from "../IconLoader";

export default function HealthProfileLegendModal({
  isOpen,
  onClose,
}: DefaultDialogImplementationProps) {
  const { t } = useTranslation();

  return (
    <DefaultDialog
      fullWidth={true}
      isOpen={isOpen}
      onClose={onClose}
      animate={true}
      closeButton={false}
      paperStyleProps={{
        ...slideUpModalStyle,
      }}
      label={t("HealthProfile.legend.title")}
    >
      <Typography
        textAlign="center"
        sx={{ fontSize: convertPxToRem(20) }}
        variant="h2"
      >
        {t("HealthProfile.legend.title")}
      </Typography>
      <Stack direction="row" spacing={2} alignItems="center">
        <IconLoader
          icon="HealthScoreIndicatorIconsSet"
          sx={{ fontSize: convertPxToRem(34), fill: "none" }}
        />
        <Typography variant="body1">
          {t("HealthProfile.legend.healthScoreExplainer")}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <IconLoader
          icon="CheckVerifiedFilledIcon"
          color="primary"
          sx={{ fontSize: convertPxToRem(34) }}
        />
        <Typography variant="body1">
          {t("HealthProfile.legend.verifiedStatusExplainer")}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <IconLoader
          icon="CheckVerifiedFilledIcon"
          color="secondary"
          sx={{ fontSize: convertPxToRem(34) }}
        />
        <Typography variant="body1">
          {t("HealthProfile.legend.expiredVerifiedStatusExplainer")}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <FaceIconBrand
          color="secondary"
          sx={{ fontSize: convertPxToRem(34), p: 0.7 }}
        />
        <Typography variant="body1">
          {t("HealthProfile.legend.selfReportedFieldsExplainer")}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <IconLoader
          icon="HealthScoreMeasurementColorsIcon"
          sx={{ fontSize: convertPxToRem(34), fill: "none" }}
        />
        <Typography variant="body1">
          {t("HealthProfile.legend.healthMeasurementColorsExplainer")}
        </Typography>
      </Stack>
      <Button variant="outlined" onClick={() => onClose()} fullWidth>
        {t("common.backButton")}
      </Button>
    </DefaultDialog>
  );
}
