import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Stack, Typography } from "@mui/material";
import HomeIcon from "../../theme/icons/HomeIcon";
import FullscreenBackground from "../FullscreenBackground";
import WatermarkIcon from "../WatermarkIcon";

interface HealthProfileQuestionnaireCompleteProps {
  firstName: string;
}

export default function HealthProfileQuestionnaireComplete({
  firstName,
}: HealthProfileQuestionnaireCompleteProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onViewHealthProfileButtonClick() {
    navigate("/home/my-health/health-profile");
  }

  function onGoHomeButtonClick() {
    navigate("/home");
  }

  return (
    <Stack
      justifyContent="flex-end"
      color="primary.contrastText"
      sx={{
        height: "100%",
      }}
    >
      <WatermarkIcon
        extra={{
          alignSelf: "center",
          position: "absolute",
          top: "20%",
          left: "50%",
          transform: "translate(-50%, 0)",
        }}
        height={128}
        width={128}
        iconSize="large"
        color={"accent1.300"}
        foreground={"accent1.main"}
        Icon={"HeartShieldFingerprintIcon"}
      />
      <Stack textAlign="center" spacing={2} py={5}>
        <Typography variant="h1" color="inherit">
          {firstName?.length > 0
            ? t("HealthProfileQuestionnaireComplete.title.withName", {
                name: firstName,
              })
            : t("HealthProfileQuestionnaireComplete.title.withoutName")}
        </Typography>
        <Typography variant="h3" color="inherit">
          {t("HealthProfileQuestionnaireComplete.subtitle")}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Button color="info" onClick={onViewHealthProfileButtonClick}>
          {t("HealthProfileQuestionnaireComplete.viewHealthProfileButton")}
        </Button>
        <Button
          variant="outlined"
          color="info"
          onClick={onGoHomeButtonClick}
          startIcon={<HomeIcon />}
        >
          {t("HealthProfileQuestionnaireComplete.goHomeButton")}
        </Button>
      </Stack>
      <FullscreenBackground color="primary.main" />
    </Stack>
  );
}
