import { InputLabel, Stack, TextField, Typography } from "@mui/material";
import HealthProfileQuestionnaireComplete from ".";
import { useState } from "react";

export default function HealthProfileQuestionnaireCompleteExamples() {
  const [firstName, setFirstName] = useState("");

  function onNameInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setFirstName(value);
  }

  return (
    <Stack sx={{ height: "100vh", p: 2 }}>
      <Stack spacing={1} bgcolor="neutral.100" p={2}>
        <InputLabel htmlFor="name">
          <Typography color="neutral.700" variant="body2" fontWeight={300}>
            Name to display in component
          </Typography>
        </InputLabel>
        <TextField
          id="name"
          value={firstName}
          onInput={onNameInput}
          size="small"
        />
      </Stack>
      <HealthProfileQuestionnaireComplete firstName={firstName} />
    </Stack>
  );
}
